import React, { useContext } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import DisplayAgeRange from "../resources/display_age_range";
import DisplayTag from "../resources/display_tag";

import { DiscussionQuestionTemplate } from "../../types";
import DiscussionQuestionContext from "../discussion_questions/discussion_question_context";

interface Props {
  questionTemplate: DiscussionQuestionTemplate;
  extraClasses?: string;
  onSelected?: (questionTemplate: DiscussionQuestionTemplate) => void;
  onDeleteClicked?: (questionTemplate: DiscussionQuestionTemplate) => void;
}

export default function QuestionTemplate({ questionTemplate, extraClasses, onSelected, onDeleteClicked }: Props) {
  const { user } = useContext(DiscussionQuestionContext);
  const isCategory = !!questionTemplate.category;

  const onClick = (e) => {
    e.preventDefault();
    onSelected?.(questionTemplate);
  };

  const handleDeleteClicked = (e, questionTemplate: DiscussionQuestionTemplate) => {
    e.preventDefault();
    e.stopPropagation();
    onDeleteClicked?.(questionTemplate);
  };

  return (
    <a href="#" onClick={onClick} className={classNames("d-flex flex-column text-decoration-none text-reset border rounded p-3", extraClasses)}>
      <div className="row">
        <div className={classNames("col-md-12", isCategory ? "mb-1" : null)}>
          <div className="d-flex">
            <div className="d-flex flex-wrap flex-grow-1">
              <div className="mr-2">{questionTemplate.prompt}</div>
              <div>
                <DisplayAgeRange
                  ageRanges={questionTemplate.age_ranges}
                  baseKey={`discussion_question_template_${questionTemplate.id}_`}
                  showTooltip={true}
                />
              </div>
            </div>

            {(questionTemplate.template_type === "personal" && questionTemplate.user_id === user.id) &&
              <div>
                <OverlayTrigger placement="top" overlay={
                  <Tooltip id={`delete-question-template-${questionTemplate.id}`}>Delete</Tooltip>
                }>
                  <Button type="button" variant="link" size="sm" className="text-red" onClick={e => handleDeleteClicked(e, questionTemplate)}>
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </OverlayTrigger>
              </div>
            }
          </div>
        </div>
      </div>

      {isCategory &&
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap">
              {questionTemplate.category &&
                <div className="mr-2">
                  <DisplayTag tag={{ id: questionTemplate.id, name: questionTemplate.category }} enableClicks={false} />
                </div>
              }
            </div>
          </div>
        </div>
      }
    </a>
  );
}