import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import { useDebouncedCallback } from "use-debounce";

import { fetchData } from "../global_functions";
import ResourceThumbnail from "../resources/resource_thumbnail";
import DisplayTag from "../resources/display_tag";
import DisplayAgeRange from "../resources/display_age_range";

import styles from "./homepage_search.module.scss";

const MAX_DESC_LENGTH = 100;

function HomepageSearch() {
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (searchQuery.length < 1) {
      setResults([]);
      return;
    }

    performSearch(searchQuery);
  }, [searchQuery]);

  const performSearch = useDebouncedCallback(
    async (query: string) => {
      setLoading(true);
      setFirstLoad(false);

      const response = await fetchData({
        url: "/search/homepage_search",
        params: { q: query }
      });

      setResults(response.data.results);
      setLoading(false);
    },
    400
  );

  return (
    <>
      <div className="mb-3">
        <InputGroup>
          <Form.Control
            type="input"
            placeholder="Enter a topic (ex: photosynthesis, american history)"
            aria-label="Search"
            size="lg"
            onChange={e => setSearchQuery(e.target.value)}
          />
        </InputGroup>
      </div>


      {loading &&
        <Spinner animation="border" variant="primary" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      }

      {!loading && !firstLoad &&
        <>
          <div className="card-deck">
            {results.map((result, index) =>
              <DisplayResource resource={result} key={index} />
            )}
          </div>

          {searchQuery.length > 0 && results.length < 1 &&
            <p>No results found. Try changing your search query.</p>
          }

          {results.length > 0 &&
            <a className="mt-4 btn btn-lg btn-ch-blue font-weight-bold" href="/signup?homepage_search=true">
              Sign up to view these and more clips
            </a>
          }
        </>
      }
    </>
  );
}

function DisplayResource({ resource }) {
  // Lowercase subject names
  const subjects = resource.subjects.map(subject => ({ ...subject, name: subject.name.toLowerCase() }));

  return (
    <div className={classNames("card", styles.card)}>
      <ResourceThumbnail resourceObj={resource} extraClasses="card-img-top" />

      <div className="card-body">
        <div className="card-title">
          {resource.title}
        </div>

        <div className="card-text">
          <div>
            <DisplayAgeRange ageRanges={resource.age_ranges} size="sm" showTooltip={true} />
          </div>

          <div className="resource-tag">
            <div className="d-flex flex-wrap mt-2">
              {
                subjects.concat(resource.subject_tags).map((tag, index) => (
                  <div className="mb-2 mr-2" key={index}>
                    <DisplayTag tag={tag} enableClicks={false} />
                  </div>
                ))
              }
            </div>
          </div>

          <div>
            {resource.short_desc.length > MAX_DESC_LENGTH ?
              `${resource.short_desc.substring(0, MAX_DESC_LENGTH)}...` : resource.short_desc
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export function renderHomepageSearch(elementId) {
  const node = ReactDOM.render(
    <HomepageSearch />,
    document.getElementById(elementId)
  );

  return node;
}